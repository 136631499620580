import React, {Component} from 'react';
import PosixGroupsGateway from "../../../Gateways/LDAP/PosixGroup.";
import {Message, Item } from "semantic-ui-react";
import {faUserFriends} from '@fortawesome/pro-regular-svg-icons';
import ItemContentLoading from "../../../Layout/ItemContentLoading";
import LdapProperty from "./LdapProperty";

export default class PosixGroupMembership extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: undefined,
            errorMessage: undefined
        };

    }

    loadedLdapProfile = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,data: response.data});
        }
        else if(response.status === 204)
        {
            this.setState({loading: false,data: null});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load PosixGroups Membership Data'});
    };

    componentDidMount() {
        PosixGroupsGateway.loadProfile(this.loadedLdapProfile,this.errorLoading);
    }


    render() {

        const DivLoading = this.state.loading &&
            ItemContentLoading();

        const DivErrorMessage = this.state.errorMessage &&
            <Message negative>{this.state.errorMessage} </Message>;

        const Placeholder = <Message info> Sie sind in keiner Posix Gruppe Mitglied</Message>;

        const PosixGroupsElems = this.state.data && this.state.data.map((posixgroup) =>
            <LdapProperty FontAwesomeIcon={faUserFriends} Content={posixgroup.cn} Description={posixgroup.gidNumber} />
        );

        const PosixGroupMembershipContent = this.state.data &&
            <div>
                <Message info>
                    Sie sind Mitglied in den folgenden Posix Gruppen
                </Message>
                <Item.Group divided>
                    { PosixGroupsElems.map((property,i) => (  <Item key={i}> {property} </Item>  )) }
                </Item.Group>
            </div>

        return(
            <div>
                { DivLoading || PosixGroupMembershipContent || DivErrorMessage || Placeholder }
            </div>
            );
    }
}


