import React, {Component} from 'react';
import PosixAccountGateway from "../../../Gateways/LDAP/PosixAccount";
import {Item, Message} from "semantic-ui-react";
import {
    faBrowser,
    faFingerprint,
    faGlobeEurope,
    faHome,
    faIdCardAlt,
    faPassport,
} from "@fortawesome/pro-regular-svg-icons";
import ItemContentLoading from "../../../Layout/ItemContentLoading";
import LdapProperty from "./LdapProperty";

export default class PosixAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: undefined,
            errorMessage: undefined
        };

    }

    loadedLdapProfile = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,data: response.data});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load PosixAccount Data'});
    };

    componentDidMount() {
        PosixAccountGateway.loadProfile(this.loadedLdapProfile,this.errorLoading);
    }


    render() {

        const DivLoading = this.state.loading &&
            ItemContentLoading();

        const DivErrorMessage = this.state.errorMessage &&
            <Message negative>{this.state.errorMessage} </Message>;

        const Placeholder = <Message info> No PosixAccount Data Available</Message>;


        const dn = this.state.data && this.state.data.dn &&
            <LdapProperty FontAwesomeIcon={faGlobeEurope} Content={this.state.data.dn} Description={"Distinguished Name"} />;

        const cn = this.state.data && this.state.data.cn &&
            <LdapProperty FontAwesomeIcon={faIdCardAlt} Content={this.state.data.cn} Description={"Common Name"} />;

        const uid = this.state.data && this.state.data.uid &&
            <LdapProperty FontAwesomeIcon={faFingerprint} Content={this.state.data.uid} Description={"User ID"} />;

        const uidNumber = this.state.data && this.state.data.uidNumber &&
            <LdapProperty FontAwesomeIcon={faPassport} Content={this.state.data.uidNumber} Description={"User ID Number"} />;

        const gidNumber = this.state.data && this.state.data.gidNumber &&
            <LdapProperty FontAwesomeIcon={faPassport} Content={this.state.data.gidNumber} Description={"Group ID"} />;

        const homeDirectory = this.state.data && this.state.data.homeDirectory &&
            <LdapProperty FontAwesomeIcon={faHome} Content={this.state.data.homeDirectory} Description={"Heimverzeichnis"} />;

        const loginShell = this.state.data && this.state.data.loginShell &&
            <LdapProperty FontAwesomeIcon={faBrowser} Content={this.state.data.loginShell} Description={"Shell"} />;

        const PropertyData = [
            dn,cn,homeDirectory,uid,uidNumber,gidNumber,loginShell
        ].filter(e => e!=null);


        const PosixAccountData = (PropertyData.length>1) &&
            <Item.Group divided >
                { PropertyData.map((property,i) => (  <Item key={i}> {property} </Item>  )) }
            </Item.Group>;

        return(
            <div>
                {DivLoading || PosixAccountData || DivErrorMessage || Placeholder }
            </div>
        );
    }
}


