import React, {Component} from 'react';
import LdapProfileGateway from "../../Gateways/LdapProfileGateway";
import {Accordion, Label} from "semantic-ui-react";
import LdapPassword from "./LDAP/LdapPassword";
import LdapEmail from "./LDAP/LdapEmail";



export default class LdapSettingsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: undefined,
            errorMessage: undefined
        };

    }

    loadedLdapProfile = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,data: response.data});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load Projects'});
    };

    componentDidMount() {
        LdapProfileGateway.loadProfile(this.loadedLdapProfile,this.errorLoading);
    }


    render() {
        const panels = [
            {
                key: 'password',
                title: {
                    content: <Label color='black' content={'Passwort ändern'}/>
                },
                content: {
                  content: (<LdapPassword/>)
                }
            },
            {
                key: 'email',
                title: {
                    content: <Label color='black' content={'Email ändern'}/>
                },
                content: {
                  content: (<LdapEmail/>)
                }
            },
        ];

        return (
            <Accordion defaultActiveIndex={-1} panels={panels} />
        );
    }
}


