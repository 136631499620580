import React, {Component} from 'react';
import GroupOfNamessGateway from "../../../Gateways/LDAP/GroupOfNames";
import {Message, Item } from "semantic-ui-react";
import {faUserFriends} from '@fortawesome/pro-regular-svg-icons';
import ItemContentLoading from "../../../Layout/ItemContentLoading";
import LdapProperty from "./LdapProperty";

export default class GroupOfNamesMembership extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: undefined,
            errorMessage: undefined
        };

    }

    loadedLdapProfile = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,data: response.data});
        }
        else if(response.status === 204)
        {
            this.setState({loading: false,data: null});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load GroupOfNamess Membership Data'});
    };

    componentDidMount() {
        GroupOfNamessGateway.loadProfile(this.loadedLdapProfile,this.errorLoading);
    }


    render() {

        const DivLoading = this.state.loading &&
            ItemContentLoading();

        const DivErrorMessage = this.state.errorMessage &&
            <Message negative>{this.state.errorMessage} </Message>;

        const Placeholder = <Message info> Sie sind in keiner Gruppe Mitglied</Message>;

        const GroupOfNamessElems = this.state.data && this.state.data.map((posixgroup) =>
            <LdapProperty FontAwesomeIcon={faUserFriends} Content={posixgroup.cn} />
        );

        const GroupOfNamesMembershipContent = this.state.data &&
            <div>
                <Message info>
                    Sie sind Mitglied in den folgenden Gruppen
                </Message>
                <Item.Group divided>
                    { GroupOfNamessElems.map((property,i) => (  <Item key={i}> {property} </Item>  )) }
                </Item.Group>
            </div>

        return(
            <div>
                { DivLoading || GroupOfNamesMembershipContent || DivErrorMessage || Placeholder }
            </div>
            );
    }
}


