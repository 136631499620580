import axios from 'axios';

class PosixAccountGateway
{
    static loadProfile(successCallback, errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/ldap/PosixAccount/').then(successCallback).catch(errorCallback);
    }
}

export default PosixAccountGateway;