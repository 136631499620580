import React from 'react';
import {
    Item,
    ItemContent,
} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";

const ItemContentLoading = () => (
    <ItemContent>
        <Item.Description>
            <FontAwesomeIcon icon={faSpinnerThird} spin/> Loading
        </Item.Description>
    </ItemContent>
);

export default ItemContentLoading;
