import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import AuthStorage from "./Storages/AuthStorage";
import axios from "axios/index";
import LogOffPage from "./Pages/LogOffPage";
import MyDataPage from "./Pages/MyDataPage";
import {SessionContext} from "./Context/SessionContext";
import SettingsPage from "./Pages/SettingsPage";

class App extends Component
{

    constructor(props)
    {
        super(props);
        axios.defaults.timeout = 10000;
        const session_key = AuthStorage.getSessionKey();
        if(session_key)
        {
            axios.defaults.headers.common['Authorization'] = session_key;
            this.state = {  session_id: session_key };
        }
        else
        {
            this.state = {  session_id: false };
        }
    }

    setSession = (session) => {
        this.setState({
            session_id: session
        })
        // console.log("Session Change");
    }

    render()
    {
        const LoggedInRoutes=
        <Switch>
            <Route path="/logoff" component={LogOffPage} />
            <Route path="/mydata" component={MyDataPage}/>
            <Route path="/settings" component={SettingsPage}/>
            <Route path="/" component={HomePage}/>
        </Switch>;

        const LoggedOutRoutes=
        <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/" component={LoginPage}/>
        </Switch>;

        const DivSessionContext =
            <SessionContext.Provider value={{
                value: this.state.session_id,
                setValue: this.setSession
            }}>
                {this.state.session_id ?  LoggedInRoutes: LoggedOutRoutes}
            </SessionContext.Provider>

        return (
            DivSessionContext
        );
    }

}

export default App;
