import axios from 'axios';

class LdapProfileGateway
{
    static loadProfile(successCallback, errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/me/ldap/').then(successCallback).catch(errorCallback);
    }


    static changePassword(password,successCallback,errorCallback)
    {
        axios.patch(process.env.REACT_APP_API_URL+'/me/ldap/',{
            password: password
        }).then(successCallback).catch(errorCallback)
    }

    static changeEmail(email,successCallback,errorCallback)
    {
        axios.patch(process.env.REACT_APP_API_URL+'/me/ldap/',{
            email: email
        }).then(successCallback).catch(errorCallback)
    }
}

export default LdapProfileGateway;