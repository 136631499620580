import axios from 'axios';

class LocalProfileGateway
{
    static loadProfile(successCallback, errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/me/local/').then(successCallback).catch(errorCallback);
    }

    static changePassword(password,successCallback,errorCallback)
    {
        axios.patch(process.env.REACT_APP_API_URL+'/me/local/',{
            password: password
        }).then(successCallback).catch(errorCallback)
    }

}

export default LocalProfileGateway;