import React, {Component} from 'react';
import {Icon, Item, ItemContent, Popup} from "semantic-ui-react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSquareFull} from "@fortawesome/free-solid-svg-icons";

export default class LdapProperty extends Component {

    render() {
        const icon= this.props.FontAwesomeIcon && <Icon size='big' > <FontAwesomeIcon fixedWidth icon={this.props.FontAwesomeIcon} mask={faSquareFull} size="1x" transform="shrink-7" /> </Icon>;

        return(
            <ItemContent verticalAlign='middle'>
                <Item.Header>
                    { this.props.Description ? <Popup content={this.props.Description} trigger={icon}/> : icon }
                    {this.props.Content}
                </Item.Header>
            </ItemContent>
        );
    }
}


