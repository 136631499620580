import React, {Component} from 'react';
import InetOrgPersonGateway from "../../../Gateways/LDAP/InetOrgPerson";
import {Message, Item } from "semantic-ui-react";
import {faEnvelopeOpen, faGlobeEurope, faIdCardAlt, faUser} from '@fortawesome/pro-regular-svg-icons';
import ItemContentLoading from "../../../Layout/ItemContentLoading";
import LdapProperty from "./LdapProperty";

export default class InetOrgPerson extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: undefined,
            errorMessage: undefined
        };

    }

    loadedLdapProfile = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,data: response.data});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load InetOrgPerson Data'});
    };

    componentDidMount() {
        InetOrgPersonGateway.loadProfile(this.loadedLdapProfile,this.errorLoading);
    }


    render() {

        const DivLoading = this.state.loading &&
            ItemContentLoading();

        const DivErrorMessage = this.state.errorMessage &&
            <Message negative>{this.state.errorMessage} </Message>;

        const Placeholder = <Message info> No InetOrgPerson Data Available</Message>;

        const dn = this.state.data && this.state.data.dn &&
            <LdapProperty FontAwesomeIcon={faGlobeEurope} Content={this.state.data.dn} Description={"Distinguished Name"} />;

        const cn = this.state.data && this.state.data.cn &&
            <LdapProperty FontAwesomeIcon={faIdCardAlt} Content={this.state.data.cn} Description={"Common Name"} />;

        const sn = this.state.data && this.state.data.sn &&
            <LdapProperty FontAwesomeIcon={faUser} Content={this.state.data.sn} Description={"Nachname"} />;

        const givenName = this.state.data && this.state.data.givenName && this.state.data.givenName.length > 0 &&
            <LdapProperty FontAwesomeIcon={faUser} Content={this.state.data.givenName.join(' ')} Description={"Vorname"} />;

        const InetItemOrgEmail = this.state.data && this.state.data.mail &&
            <LdapProperty FontAwesomeIcon={faEnvelopeOpen} Content={this.state.data.mail} Description={"Email"} />;

        const PropertyData = [
            dn, cn, sn, givenName, InetItemOrgEmail,
        ].filter(e => e!=null);


        const ItemInetOrgContents = (PropertyData.length>1) &&
            <Item.Group divided>
                { PropertyData.map((property,i) => (  <Item key={i}> {property} </Item>  )) }
            </Item.Group>;

        return(
            <div>
                {DivLoading || ItemInetOrgContents || DivErrorMessage || Placeholder }
            </div>
            );
    }
}


