import React, {Component} from 'react';
import {Button, Form, Icon, Message, Segment} from "semantic-ui-react";
import LdapProfileGateway from "../../../Gateways/LdapProfileGateway";

export default class LdapPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            password2: '',
            readable: false,
            loading: false,
            data: undefined,
            errorMessage: undefined,
            recaptcha: undefined,

        };

    }

    handleChangePassword = (event) => {
        this.setState({password: event.target.value});
    };


    changePassword = (event) => {
        event.preventDefault();
        this.setState({loading:true});
        if(this.state.password.trim() === "")
        {
            this.setState({loading: false, errorMessage: "Enter a Password!"});
        }
        else
        {
            LdapProfileGateway.changePassword(this.state.password,this.handlePasswordChangeResponse,this.handlePasswordChangeError);
        }
    }

    handlePasswordChangeResponse = (response) => {
        if(response.data)
        {
            this.setState({loading:false,successMessage: "Password change Succeeded"})
        }
    }

    handlePasswordChangeError = (error) => {

        if(error.response && error.response.status === 422 && error.response.data.error)
        {
            this.setState({loading: false, errorMessage: error.response.data.error });
        }
        else if(error.response && error.response.status === 400  && error.response.data.error)
        {
            this.setState({loading: false, errorMessage: error.response.data.error});
        }
        else if(error.response && error.response.status === 403  && error.response.data.error)
        {
            this.setState({loading: false, errorMessage: error.response.data.error});
        }
        else if(error.response && error.response.status === 400 )
        {
            this.setState({loading: false, errorMessage: "An Error has Occured"});
        }
        else if(error.status)
        {
            this.setState({loading:false, errorMessage: "Check Network Connection"});
        }
        else {
            this.setState({loading:false, errorMessage: "Something has gone Wrong"});
        }
    }

    handleClickLock = () => {
        this.setState({readable: ! this.state.readable})
    }

    render() {


        const DivErrorMessage = this.state.errorMessage &&
            <Message negative>{this.state.errorMessage} </Message>;

        const DivSuccessMessage = this.state.successMessage &&
            <Message positive>{this.state.successMessage}</Message>

        const DivAnyMessage = DivSuccessMessage || DivErrorMessage;

        const LockIcon = <Icon link name={this.state.readable ? 'eye': 'lock'} onClick={this.handleClickLock}/>

        return(
            <div><Form size='large'>
                <Segment stacked>
                    <p> Neues Passwort</p>
                    <Form.Input fluid icon={LockIcon} iconPosition='left' placeholder='Password' onChange={this.handleChangePassword}  type={ this.state.readable ? 'input' : 'password' } />

                    <Button color='black' fluid size='large' onClick={this.changePassword}>
                        Ändern
                    </Button>
                    { DivAnyMessage  }
                </Segment>
            </Form>
            </div>
        );
    }
}


