import React, {Component} from 'react';
import {Button, Form, Message, Segment} from "semantic-ui-react";
import LdapProfileGateway from "../../../Gateways/LdapProfileGateway";

export default class LdapEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            loading: false,
            data: undefined,
            errorMessage: undefined,
            recaptcha: undefined,

        };

    }

    handleChangeEmail = (event) => {
        this.setState({email: event.target.value});
    };


    changeEmail = (event) => {
        event.preventDefault();
        this.setState({loading:true});
        if(this.state.email.trim() === "")
        {
            this.setState({loading: false, errorMessage: "Enter an Email Address!"});
        }
        else
        {
            LdapProfileGateway.changeEmail(this.state.email,this.handleEmailChangeResponse,this.handleEmailChangeError);
        }
    }

    handleEmailChangeResponse = (response) => {
        if(response.data)
        {
            this.setState({loading:false,successMessage: "Email change Succeeded"})
        }
    }

    handleEmailChangeError = (error) => {

        if(error.response && error.response.status === 422 && error.response.data.error)
        {
            this.setState({loading: false, errorMessage: error.response.data.error });
        }
        else if(error.response && error.response.status === 400  && error.response.data.error)
        {
            this.setState({loading: false, errorMessage: error.response.data.error});
        }
        else if(error.response && error.response.status === 403  && error.response.data.error)
        {
            this.setState({loading: false, errorMessage: error.response.data.error});
        }
        else if(error.response && error.response.status === 400 )
        {
            this.setState({loading: false, errorMessage: "An Error has Occured"});
        }
        else if(error.status)
        {
            this.setState({loading:false, errorMessage: "Check Network Connection"});
        }
        else {
            this.setState({loading:false, errorMessage: "Something has gone Wrong"});
        }
    }

    handleClickLock = () => {
        this.setState({readable: ! this.state.readable})
    }

    render() {


        const DivErrorMessage = this.state.errorMessage &&
            <Message negative>{this.state.errorMessage} </Message>;

        const DivSuccessMessage = this.state.successMessage &&
            <Message positive>{this.state.successMessage}</Message>

        const DivAnyMessage = DivSuccessMessage || DivErrorMessage;


        return(
            <div><Form size='large'>
                <Segment stacked>
                    <p> Neue Email-Adresse</p>
                    <Form.Input fluid icon='mail' iconPosition='left' placeholder='Email' onChange={this.handleChangeEmail}  type={'input'} />

                    <Button color='black' fluid size='large' onClick={this.changeEmail}>
                        Ändern
                    </Button>
                    { DivAnyMessage  }
                </Segment>
            </Form>
            </div>
        );
    }
}


