import axios from 'axios';

class InetOrgPersonGateway
{
    static loadProfile(successCallback, errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/ldap/InetOrgPerson/').then(successCallback).catch(errorCallback);
    }
}

export default InetOrgPersonGateway;