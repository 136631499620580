import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import './index.css';
import App from './App';

// import * as serviceWorker from './serviceWorker';
// if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    import './dev.css';


ReactDOM.render(<HashRouter><App/></HashRouter>, document.getElementById('content'));
