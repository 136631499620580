import React, {Component} from 'react';
import LocalProfileGateway from "../../Gateways/LocalProfileGateway";
import {Accordion, Label} from "semantic-ui-react";
import LocalPassword from "./Local/LocalPassword";



export default class LocalSettingsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: undefined,
            errorMessage: undefined
        };

    }

    loadedLocalProfile = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,data: response.data});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load Projects'});
    };

    componentDidMount() {
        LocalProfileGateway.loadProfile(this.loadedLocalProfile,this.errorLoading);
    }


    render() {
        const panels = [
            {
                key: 'password',
                title: {
                    content: <Label color='black' content={'Passwort ändern'}/>
                },
                content: {
                  content: (<LocalPassword/>)
                }
            },
        ];

        return (
            <Accordion defaultActiveIndex={-1} panels={panels} />
        );
    }
}


