import React, {Component} from 'react';
import UserGateway from "../Gateways/UserGateway";
import AuthStorage from "../Storages/AuthStorage";
import {Container, Header, Message} from "semantic-ui-react";
import FixedMenuLayout from "../Layout/FixedMenuLayout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import LdapSettingsPage from "./Settings/LdapSettingsPage";
import LocalSettingsPage from "./Settings/LocalSettingsPage";

class SettingsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMessage: undefined,
            me: undefined
        };
    }

    loadedMe = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,me: response.data});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load Profile Data'});
        if(error.response.status === 401)
        {
            AuthStorage.removeSessionKey();
        }
    };

    componentDidMount() {
        UserGateway.getMe(this.loadedMe,this.errorLoading);
    }


    render() {
        const DivErrorMessage = this.state.errorMessage &&
            <Message warning>{this.state.errorMessage} </Message>;
        const no_profile = <Message warning>Unsupported Profile</Message>

        const me_local = this.state.me && this.state.me.type && this.state.me.type==='local' &&
            <LocalSettingsPage />

        const me_ldap = this.state.me && this.state.me.type && this.state.me.type==='ldap' &&
            <LdapSettingsPage />;

        if (this.state.loading) {
            return(
                <FontAwesomeIcon icon={faSpinnerThird} spin />
            );
        }
        else {
            return(
                <div>
                    {FixedMenuLayout()}
                    <Container text style={{ marginTop: '7em' }}>
                        <Header as='h1'>Einstellungen</Header>
                        {DivErrorMessage || me_ldap || me_local || no_profile}
                    </Container>
                </div>
            );
        }

    }
}

export default SettingsPage;
