import React from 'react';
import {
    Container,
    Menu,
    Icon, Popup,
} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog, faSignOutAlt,faPortrait} from "@fortawesome/pro-regular-svg-icons";


const FixedMenuLayout = () => (
    <Menu fixed='top' >
        <Container>
            <Menu.Item as={Link} to ='/' header>
                SERVICEPANEL NG
            </Menu.Item>
            <Popup
                trigger={
                    <Menu.Item as={Link} to={'/mydata'}>
                        <Icon>
                            <FontAwesomeIcon fixedWidth icon={faPortrait}/>
                        </Icon>
                    </Menu.Item>}
                content="Meine Daten"
            />

            <Menu.Menu position='right'>
                    <Popup
                        trigger={
                            <Menu.Item as={Link} to={'/settings'}>
                                <Icon>
                                    <FontAwesomeIcon fixedWidth icon={faCog}/>
                                </Icon>
                            </Menu.Item>}
                        content="Einstellungen"
                    />
                    <Popup
                        trigger={
                            <Menu.Item as={Link} to={'/logoff'}>
                                <Icon>
                                    <FontAwesomeIcon fixedWidth icon={faSignOutAlt}/>
                                </Icon>
                            </Menu.Item>}
                        content="Abmelden"
                    />

            </Menu.Menu>
        </Container>
    </Menu>
);

export default FixedMenuLayout;