import React,{Component} from 'react';
import AuthStorage from "../Storages/AuthStorage";
import {Grid, Header, Message} from "semantic-ui-react";
import {SessionContext} from "../Context/SessionContext";

class LogOffPage extends Component {

    static contextType = SessionContext;

    constructor(props)
    {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentDidMount() {
        this.logoff();
        this.props.history.push("/");//redirect to main after dis authentication
        // window.location.reload();
    }

    logoff = () => {
        this.setState({loading: true});
        AuthStorage.removeSessionKey();
        this.context.setValue(false);
        this.setState({loading: false});
    }


    render()
    {
        const GoodByeMessage = <Message>
            <Header> You are beeing Logged Off</Header>
            <p> Your will be forwarded to the Landing Page as soon as you are checked out.</p>
            <a href={"/#"}>Click here, if you are not beeing forwarded. </a>
        </Message>;

        const DivGoodBye =
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    {GoodByeMessage}
                </Grid.Column>
            </Grid>;
        return (
            DivGoodBye
        );
    }

}

export default LogOffPage;
