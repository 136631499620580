import React, {Component} from 'react';
import LdapProfileGateway from "../../Gateways/LdapProfileGateway";
import {Tab} from "semantic-ui-react";

import InetOrgPerson from "./LDAP/InetOrgPerson";
import PosixAccount from "./LDAP/PosixAccount";
import PosixGroupMembership from "./LDAP/PosixGroupMembership";
import GroupOfNamesMembership from "./LDAP/GroupOfNamesMembership";

export default class LdapProfilePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: undefined,
            errorMessage: undefined
        };

    }

    loadedLdapProfile = (response) => {
        if(response.status === 200)
        {
            this.setState({loading:false,data: response.data});
        }
    };

    errorLoading = (error) => {
        this.setState({loading: false, errorMessage: 'Could not Load Projects'});
    };

    componentDidMount() {
        LdapProfileGateway.loadProfile(this.loadedLdapProfile,this.errorLoading);
    }


    render() {

        const panes = [
            {
                menuItem: 'Posix Account',
                render: () => <Tab.Pane attached='top'> <PosixAccount/> </Tab.Pane>,
            },
            {
                menuItem: 'InetOrgPerson',
                render: () => <Tab.Pane attached='top'> <InetOrgPerson/> </Tab.Pane>,
            },
            {
                menuItem: 'Posix Groups',
                render: () => <Tab.Pane attached='top'> <PosixGroupMembership/> </Tab.Pane>,
            },
            {
                menuItem: 'Group Of Names',
                render: () => <Tab.Pane attached='top'> <GroupOfNamesMembership/> </Tab.Pane>,
            },
        ];

        return (
            <Tab menu={{  borderless: true, attached: false }} panes={panes} />
        );
    }
}


