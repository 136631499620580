import axios from 'axios';

class GroupOfNamessGateway
{
    static loadProfile(successCallback, errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/ldap/GroupOfNames/').then(successCallback).catch(errorCallback);
    }
}

export default GroupOfNamessGateway;