import axios from 'axios';

class PosixGroupsGateway
{
    static loadProfile(successCallback, errorCallback)
    {
        axios.get(process.env.REACT_APP_API_URL+'/ldap/PosixGroup/').then(successCallback).catch(errorCallback);
    }
}

export default PosixGroupsGateway;